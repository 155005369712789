/* Reset styles */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

/* Body styles */
body {
    font-family: "Helvetica", sans-serif;
    background-color: #f2f2f2;
    color: #333;
    line-height: 1.5;
}

.navbar {
    display: flex;
    justify-content: center; /* Center the content horizontally */
    align-items: center;
    background-color: #f0f0f0;
    padding: 10px 20px;
}

.navbar-brand {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
}

.navbar-menu {
    display: flex;
    align-items: center;
}

.navbar-item {
    margin-left: 10px;
    cursor: pointer;
}

.container {
    max-width: 600px;
    margin: 0 auto;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.generated-reply-container {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 1rem;
    margin-top: 1rem;
    max-height: 300px;
    overflow-y: auto;
    font-size: 1rem;
    line-height: 1.5;
    padding: 40px;
}

.generated-reply {
    white-space: pre-wrap;
    word-break: break-all;
}

form {
    max-width: 400px;
    width: 100%;
    margin: 0 auto;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: bold;
    margin-bottom: 16px;
}

p {
    margin-bottom: 16px;
}

.button {
    display: inline-block;
    padding: 12px 24px;
    background-color: #292929;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    border: none;
    border-radius: 24px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
}

.button:hover {
    background-color: #505050;
}

.button-google {
    background-color: #fff;
    color: #555;
    border: 1px solid #ccc;
}

.button-google:hover {
    background-color: #eee;
}

.button-google-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-image: url("../images/google-logo.svg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 8px;
}

.user-profile-button {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 8px 12px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 24px;
    font-size: 14px;
    color: #333;
}

.user-profile-button:hover {
    background-color: #e0e0e0;
}

.user-profile-photo {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-right: 8px;
}

.context-menu {
    position: absolute;
    top: 40px;
    right: 0;
    padding: 8px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 100;
}

.context-menu button {
    display: block;
    margin-bottom: 4px;
}

.context-menu button:last-child {
    margin-bottom: 0;
}

form {
    margin-bottom: 16px;
}

label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
}

input[type="text"],
input[type="email"],
input[type="password"],
select,
textarea {
    display: block;
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    line-height: 1.5;
    transition: border-color 0.2s ease-in-out;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
select:focus,
textarea:focus {
    border-color: #000000;
}

a {
    color: #828282;
    text-decoration: none;
    transition: color 0.2s ease-in-out;
}

a:hover {
    color: rgb(183, 183, 183);
}

/* Popup styles */
.popup-container {
    position: fixed;
    /*  top: 0;*/
    /*  left: 0;*/
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Make sure it's above other elements */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.popup {
    background-color: #f2f2f2;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    padding: 16px;
    max-width: 400px;
    width: 100%;
    position: relative;
}

.popup-tabs {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
}

.popup-tabs button {
    margin-top: 16px;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 8px 12px;
    background-color: #f0f0f000;
    border: 0px solid #ccc;
    border-radius: 24px;
    font-size: 14px;
    color: #333;
    transition: background-color 0.2s ease-in-out;
}

.popup-tabs button.active {
    color: #333;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #e0e0e0; /* Close button hover background color */
}

.popup-tabs button:hover {
    background-color: #e0e0e0; /* Close button hover background color */
}

.popup-content {
    /* Add any specific styles for the content if needed */
    justify-content: center;
}

.close-button {
    margin-top: 16px;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 8px 12px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 24px;
    font-size: 14px;
    color: #333;
    transition: background-color 0.2s ease-in-out;
}

.close-button:hover {
    background-color: #e0e0e0; /* Close button hover background color */
}

.password-field {
    position: relative;
}

.password-toggle-button {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
}

.password-toggle-button img {
    width: 24px; /* Adjust the size as needed */
    height: 24px; /* Adjust the size as needed */
}

/* Fade animation for components */
.fade-enter {
    opacity: 0;
    transform: translateY(-10px);
}
.fade-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition:
        opacity 300ms,
        transform 300ms;
}
.fade-exit {
    opacity: 1;
    transform: translateY(0);
}
.fade-exit-active {
    opacity: 0;
    transform: translateY(-10px);
    transition:
        opacity 300ms,
        transform 300ms;
}

/* Container for all subscription cards */
.subscription-cards {
    display: flex;
    justify-content: space-between;
    gap: 20px; /* Adds spacing between cards */
}

/* Individual subscription card */
.subscription-card {
    background-color: #ffffff;
    border: 1px solid #e1e1e1;
    border-radius: 8px;
    padding: 20px;
    width: 45%; /* Adjust the width as needed */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;
    cursor: pointer;
}

.subscription-card:hover {
    transform: scale(1.03); /* Slightly scale up on hover */
}

/* Card title */
.subscription-card h3 {
    font-size: 1.5rem;
    margin: 0;
}

/* Price text */
.subscription-card p.price {
    color: #007bff; /* Blue color for price */
    font-weight: bold;
    margin: 10px 0;
}

/* List of features */
.subscription-card ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.subscription-card li {
    margin-bottom: 5px;
}

/* Subscribe button */
.subscription-card button {
    background-color: #000000;
    color: #fff;
    border: none;
    border-radius: 50px;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
}

.subscription-card button:hover {
    background-color: #0056b3; /* Darker shade on hover */
}
