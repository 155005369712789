.subscription-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999; /* Ensure the overlay appears above other content */
  
    .overlay-content {
      background-color: #fff;
      padding: 20px;
      border-radius: 10px;
      text-align: center;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    }
  }